<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'SystemSettingsHome',
              }"
            >全系統管理</b-breadcrumb-item>
            <b-breadcrumb-item active>組織管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">組織管理</h4>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <div class="col-12 col-xl-3">
          <b-form-checkbox
            v-model="onlyEnabled"
            class="pt-2"
            @change="fetchOrganizations"
          >
            只包含已啟用
          </b-form-checkbox>
        </div>
        <div
          class="col-12 col-xl-6 d-flex flex-column flex-xl-row align-items-start align-items-xl-center"
        >
          <b-button
            v-if="checkPermission([consts.ORGANIZATION_MODIFY])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="handleCreate"
          >
            <i class="fa fa-plus"></i>新增組織
          </b-button>
          <b-input-group>
            <b-form-input placeholder="搜尋組織名稱或組織編號" v-model="search" @keyup.enter="handleSearch"></b-form-input>
            <b-input-group-append>
              <b-button @click="handleSearch">
                <i class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table striped hover responsive :items="organizations" :fields="fields" :busy="isLoading">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="checkPermission([consts.MERCHANT_LIST_VIEW])"
            class="ml-2"
            variant="inverse-success"
            :to="{
              name: 'OrgMerchantList',
              params: {
                orgId: data.item.id,
              },
            }"
          ><span class="mdi mdi-lan"></span> 通路清單</b-button>

          <b-button
            v-if="checkPermission([consts.ROLE_LIST_VIEW])"
            class="ml-2"
            variant="inverse-info"
            :to="{
              name: 'OrgRoleList',
              params: {
                orgId: data.item.id,
              },
            }"
          ><span class="mdi mdi-account-star"></span> 角色管理</b-button>

          <b-button
            class="ml-2"
            variant="inverse-primary"
            :to="{
              name: 'OrganizationShow',
              params: {
                orgId: data.item.id,
              },
            }"
          ><span class="mdi mdi-eye"></span> 查看</b-button>

          <b-button
            v-if="checkPermission([consts.ORGANIZATION_MODIFY])"
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: 'OrganizationEdit',
              params: {
                orgId: data.item.id,
              },
            }"
          ><span class="mdi mdi-lead-pencil"></span> 編輯</b-button>
          <span :id="`delete-btn-${data.item.id}`" v-if="checkPermission([consts.ORGANIZATION_DELETE])">
            <b-button
              class="ml-2"
              variant="inverse-danger"
              :disabled="!canDelete(data.item)"
              @click="handleDelete(data.item.id, data.item.name, data.item.code)"
            ><span class="mdi mdi-delete"></span> 刪除</b-button>
          </span>
          <b-tooltip v-if="deleteTip(data.item)" :target="`delete-btn-${data.item.id}`" placement="right" boundary-padding="0">{{ deleteTip(data.item) }}</b-tooltip>
        </template>
      </b-table>
      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="organizationList"
          @change="(page) => fetchOrganizations(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import organizationApi from "../../../apis/organization";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  data() {
    return {
      consts,
      isLoading: false,
      onlyEnabled: true,
      search: "",
      organizations: [],
      fields: [
        {
          key: "name",
          label: "組織名稱",
        },
        {
          key: "code",
          label: "組織代碼",
        },
        {
          key: "id",
          label: "組織編號",
        },
        {
          key: "is_enabled",
          label: "狀態",
          formatter: (value) => {
            return value ? '啟用' : '停用'
          },
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchOrganizations();
  },
  methods: {
    async fetchOrganizations(page) {
      try {
        this.isLoading = true;
        let params = {
          keyword: this.search,
          page: page || this.currentPage,
          per_page: this.perPage || 15,
        }
        if (this.onlyEnabled) {
          params.is_enabled = 1;
        }
        const { data } = await organizationApi.getOrganizations(params);
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.organizations = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    handleSearch() {
      this.fetchOrganizations();
    },
    async handleDelete(orgId, name, code) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>組織名稱：${name}</div>
                <div>組織編號：${code}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await organizationApi.deleteOrganization(orgId);
              await this.fetchOrganizations();
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },
    handleCreate() {
      this.$router.push({
        name: "OrganizationCreate",
      });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    canDelete(item) {
      return item.is_enabled === 0 && item.merchants.length === 0;
    },
    deleteTip(item) {
      let tip;
      if (item.is_enabled) {
        tip = "無法刪除啟用中的組織";
      } else if (item.merchants.length !== 0) {
        tip = "組織中仍有通路，無法刪除組織";
      }
      return tip
    }
  },
};
</script>
